import employmentImg from "../../../images/desktop-service/employment-icon.jpg";
import emplyOppImg from "../../../images/desktop-service/employment--opp-icon.jpg";
import ContentStyles from "./css/Content.module.css";
import { Button } from "react-bootstrap";

const placeData =
  "Seamlessly post your needs and communicate with qualified talent.";

const mainDesktopContainer = ContentStyles.mainDesktopContainer;
const mainDesktopContainerSwitch = ContentStyles.mainDesktopContainerSwitch;
const businessContact = ContentStyles.businessContact;
const alaCartDiv = ContentStyles.alaCartDiv;
const imgWrapperBusiness = ContentStyles.imgWrapperBusiness;
const btn = ContentStyles.btn;

const price = (
  <>
    <p className={businessContact}>
      To Inquire Call or Email: <hr style={{ margin: ".3rem 1rem" }} />
      347-840-2428 <br />
      <a href="mailto:communityoflove70@gmail.com">
        <span>CommunityOfLove70@gmail.com</span>
      </a>
    </p>
    <hr style={{ border: "none" }} />
    <hr style={{ border: "none" }} />
  </>
);

const expandedSection = (
  <>
    <p style={{ letterSpacing: "-1px" }}>Are you a volunteer?</p>
    <p
      style={{
        textTransform: "initial",
        fontStyle: "italic",
        fontWeight: "500",
        fontSize: "21px",
      }}
    >
      Do you have a heart to serve in your community? Are you looking to spread
      some love and help reach the community? Than we want to hear from you!
    </p>
  </>
);

const list = (
  <div
    style={{
      fontWeight: "400",
      fontStyle: "normal",
      textTransform: "initial",
      fontSize: "21px",
    }}
  >
    We are looking for volunteers in the following areas. We would love to hear
    from you if you think you might be a great fit, see below to inquire :
    <hr style={{ margin: ".3rem 0" }} />
    ACADEMIC TUTORING
    <hr style={{ margin: ".3rem 0" }} />
    COACHING
    <hr style={{ margin: ".3rem 0" }} />
    FOREIGN LANGUAGE TEACHERS
    <hr style={{ margin: ".3rem 0" }} />
    BUILDING WEALTH
    <hr style={{ margin: ".3rem 0" }} />
    HEALTH AND WELLNESS
  </div>
);

const expandedSection2 = (
  <>
    <hr style={{ border: "none" }} />
    <p style={{ letterSpacing: "-1px" }}>Are you an Intern?</p>
  </>
);

const list2 = (
  <>
    <p style={{ margin: "-1rem 0 -.3rem 0", fontSize: "21px" }}>
      Do you want to gain experience with a compassionate and integral company?
      Do you have a heart for the community and long to see positive change?
      Then contact us today!
    </p>

    <div
      style={{
        fontWeight: "400",
        fontStyle: "normal",
        textTransform: "initial",
        margin: "2rem 0 0 0 ",
        fontSize: "21px",
      }}
    >
      We are looking for interns in the following areas, inquire below:
      <hr style={{ margin: ".3rem 0" }} />
      SITTER/CHILDCARE
      <hr style={{ margin: ".3rem 0" }} />
      PET SITTER/DOG WALKER
      <hr style={{ margin: ".3rem 0" }} />
      TRANSPORT & CARPOOLING
      <hr style={{ margin: ".3rem 0" }} />
      HOUSE CLEANING
      <hr style={{ margin: ".3rem 0" }} />
      ACADEMIC TUTORING
      <hr style={{ margin: ".3rem 0" }} />
      COACHING
      <hr style={{ margin: ".3rem 0" }} />
      FOREIGN LANGUAGE TEACHERS
      <hr style={{ margin: ".3rem 0" }} />
      BUILDING WEALTH
      <hr style={{ margin: ".3rem 0" }} />
      HEALTH AND WELLNESS
    </div>
  </>
);

const list3 = (
  <>
    <p style={{ fontSize: "21px" }}>
      We are offering hourly compensation for those employed with us. We offer
      work in the following areas: Please email updated resume to{" "}
      <a href="mailto:communityoflove70@gmail.com">
        communityoflove70@gmail.com
      </a>
      .
    </p>
    <div
      style={{
        fontWeight: "400",
        fontStyle: "normal",
        textTransform: "initial",
        margin: "2rem 0 0 0 ",
        fontSize: "21px",
      }}
    >
      We are looking for workers in the following areas, inquire below:
      <hr style={{ margin: ".3rem 0" }} />
      TRANSPORT & CARPOOLING
      <hr style={{ margin: ".3rem 0" }} />
      CONTRACTING
      <hr style={{ margin: ".3rem 0" }} />
      BABYSITTING
      <hr style={{ margin: ".3rem 0" }} />
      PET SITTING/WALKING
      <hr style={{ margin: ".3rem 0" }} />
      HOUSE KEEPING
    </div>
    <div className={alaCartDiv}>
      <p style={{ padding: "1rem 0 .4rem 0", fontStyle: "normal" }}>
        <b>Looking to recruit Instead?</b> <br />
        {/* Recruitment Services Available */}
        Call 347-840-2428
      </p>
      {/* <Button
        href="https://square.link/u/SSTpt6sc"
        style={{ fontSize: "25.5px" }}
        className={btn}
      >
        HELP ME GET HIRED
      </Button> */}
    </div>
  </>
);

const businessDataDesktop = [
  {
    id: 1,
    className: mainDesktopContainer,
    cName: imgWrapperBusiness,
    src: employmentImg,
    imgTitle: "SMALL BUSINESS RECRUITING SERVICES",
    question: "Are you an Employer?",
    article: placeData,
    monthPrice: price,
  },
  {
    id: 2,
    className: mainDesktopContainerSwitch,
    cName: imgWrapperBusiness,
    src: emplyOppImg,
    imgTitle: "FAMILY SERVICES EMPLOYMENT OPPORTUNITIES",
    question: expandedSection,
    subTitle: list,
    monthPrice: price,
  },
  {
    id: 3,
    className: mainDesktopContainerSwitch,
    cName: imgWrapperBusiness,
    src: emplyOppImg,
    imgTitle: "FAMILY SERVICES EMPLOYMENT OPPORTUNITIES",

    question: expandedSection2,
    subTitle: list2,
    monthPrice: price,
  },
  {
    id: 4,
    className: mainDesktopContainerSwitch,
    cName: imgWrapperBusiness,
    src: emplyOppImg,
    imgTitle: "FAMILY SERVICES EMPLOYMENT OPPORTUNITIES",

    question: "LOOKING FOR WORK?",
    subTitle: list3,
  },
];
export default businessDataDesktop;
