import React from "react";
import { Image, Button } from "react-bootstrap";
import HeaderStyles from "./css/Header.module.css";

const Header = () => {
  const mainContainer = HeaderStyles.mainContainer;
  const titlesContainer = HeaderStyles.titlesContainer;
  const h1Style = HeaderStyles.h1Style;
  const h2Style = HeaderStyles.h2Style;
  const divider = HeaderStyles.divider;
  const contentContainer = HeaderStyles.contentContainer;
  const h3Style = HeaderStyles.h3Style;
  const paragraph = HeaderStyles.paragraph;
  const btnSurvey = HeaderStyles.btnSurvey;

  return (
    <>
      <main className={mainContainer}>
        <section className={titlesContainer}>
          <h1 className={h1Style}>Community of Love</h1>
          <h2 className={h2Style}>Family & Business Services</h2>
          <span className={divider}></span>
        </section>
        <section className={contentContainer}>
          <h3 className={h3Style}>Welcome</h3>
          <p className={paragraph}>
            <span>
              We are proud to be offering various services for families and
              businesses alike.
            </span>
          </p>
          <p className={paragraph}>
            Services will range from employee recruitment to employment
            opportunities, childcare, pet sitting, tutoring services, foreign
            language courses, transportation services, and more. It is our heart
            to serve you and meet every need, big or small. Please take some
            time to complete the survey below so we can assess your needs to
            best benefit you. <hr />
            {/* <aside
              style={{
                padding: "1rem 0",
                backgroundColor: "#acb4c6",
                fontWeight: "bold",
                borderRadius: ".3rem",
              }}
            >
              <em>ALL SERVICES ARE FREE, PAID FOR BY OUR SPONSORS</em>
            </aside> */}
          </p>

          <Button href="/survey" className={btnSurvey}>
            Take Survey
          </Button>
        </section>
      </main>
    </>
  );
};

export default Header;
