import React from "react";
import "../style.css";
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import { Container } from "react-bootstrap";
import HeaderStyle from "../Community of Love/Header/css/Header.module.css";
import NavStyle from "./Nav.module.css";
import { useMediaQuery } from "@mui/material";

const Navigation = () => {
  const navBar = NavStyle.navBar;
  const headerInfoWrapper = HeaderStyle.headerInfoWrapper;
  const iconText = HeaderStyle.iconText;
  const contactIconWrapper = HeaderStyle.contactIconWrapper;
  const socialIconStyle = HeaderStyle.socialIconStyle;
  const socialMediaWrapper = HeaderStyle.socialMediaWrapper;
  const matchesLg = useMediaQuery("(min-width:765px)");
  const instagramLink =
    "https://www.instagram.com/communityoflove2022/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==";
  const linkedInLink = "https://www.linkedin.com/in/sharon-chatman-4a2233287/";
  const facebookLink = "https://www.facebook.com/profile.php?id=61553859627050";

  const headerInfo = (
    <>
      <div className={contactIconWrapper}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={iconText}>PH: 347-840-2428 HRS: 8AM-8PM M-F</div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={iconText}>
            EM:{" "}
            <a href="mailto:communityoflove70@gmail.com">
              communityoflove70@gmail.com
            </a>
          </div>
        </div>
      </div>
    </>
  );

  const socialMediaSection = (
    <>
      <div className={socialMediaWrapper}>
        <a
          style={{ color: "#a98519" }}
          href={facebookLink}
          className={socialIconStyle}
        >
          FACEBOOK
        </a>
        <a
          style={{ color: "#a98519" }}
          href={instagramLink}
          className={socialIconStyle}
        >
          INSTAGRAM
        </a>
        <a
          style={{ color: "#a98519" }}
          href={linkedInLink}
          className={socialIconStyle}
        >
          LINKEDIN
        </a>
      </div>
    </>
  );

  return (
    <>
      <Navbar className={navBar} expand="lg">
        {!matchesLg && (
          <div className={headerInfoWrapper}>
            {headerInfo}
            {socialMediaSection}
          </div>
        )}
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/cafe">God's Gospel Cafe</Nav.Link>
              <Nav.Link href="/services">Family Services</Nav.Link>
              <Nav.Link href="/#courses">
                Foreign Language Online Courses
              </Nav.Link>
              <Nav.Link href="/#give">Give/Sponsor</Nav.Link>
              <Nav.Link href="/#give">Request Prayer</Nav.Link>
              <Nav.Link href="/#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Navigation;
