import React from "react";
import { Row, Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ContactStyle from "../Contact.module.css";

const Title = () => {
  const contactTitle = ContactStyle.contactTitle;

  let switchTitle;
  const location = useLocation();
  if (location.pathname === "/services") {
    switchTitle = true;
  } else {
    switchTitle = false;
  }

  return (
    <>
      <Row>
        {!switchTitle && (
          <Card.Title className={contactTitle}>
            Connect with the Community
          </Card.Title>
        )}

        {switchTitle && (
          <Card.Title className={contactTitle}>Contact Us</Card.Title>
        )}

        {!switchTitle && (
          <Card.Text style={{ color: "white" }}>
            Please contact us if you have any questions regarding registration,
            or any of our services
          </Card.Text>
        )}

        {switchTitle && (
          <Card.Text style={{ color: "white" }}>
            Please contact us if you have any questions about our services, or
            if you are interested in being a client for our Small Business
            recruiting services
          </Card.Text>
        )}
      </Row>
    </>
  );
};
export default Title;
