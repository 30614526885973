import { useNavigate } from "react-router-dom";
import { Form, Card } from "react-bootstrap";
import { useState, useRef } from "react";
import SubmitButton from "./SubmitButton";
import FooterScreen from "../../Footer/FooterScreen";
import SurveyStyle from "./css/Survey.module.css";
import emailjs from "@emailjs/browser";
import Navigation from "../../ui/Nav";
import Email from "../Contact/Form Fields/Email";
import Name from "../Contact/Form Fields/Name";

const Survey = () => {
  const labelForm = SurveyStyle.labelForm;
  const form = SurveyStyle.form;
  const formStyle = SurveyStyle.formStyle;
  const communityTitle = SurveyStyle.communityTitle;
  const navigate = useNavigate();
  const refForm = useRef();

  // for checkbox
  const [boxIsChecked, setBoxIsChecked] = useState(false);
  const [choiceShow, setChoiceShow] = useState(true);

  const onChangeChoice1 = (e) => {
    if (e.target.name === "content" && e.target.checked === true) {
      setChoiceShow(false);
    }

    if (e.target.checked === false) {
      setChoiceShow(true);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs

      .sendForm(
        "service_xdgp0y4", //service id
        "template-survey", //template id
        // console.log("DATA",  refForm.current.name.value),
        refForm.current,
        "JY05Vk3JRaOF_oe0Q" //public key
      )
      .then(
        (result) => {
          //   alert("Message successfully sent!");
          navigate(`/thankyou/${refForm.current.name.value}`);
          // window.location.reload(false);
          console.log("result", result);
        },
        (error) => {
          alert("Failed to send the message, please try again");
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Navigation />
      <Card style={{ margin: "1.5rem auto 0 auto", maxWidth: "1620px" }}>
        <Card.Body className={formStyle}>
          <Card.Title className={communityTitle}>
            Community of Love <br /> Feed Back Survey
          </Card.Title>
          <Form
            id="Survey"
            ref={refForm}
            onSubmit={sendEmail}
            className={form}
            autoComplete="on"
          >
            <Name />
            <Email />
            <Form.Label className={labelForm}>
              What services are you most interested in?
            </Form.Label>
            <Form.Control
              style={{ borderRadius: "0" }}
              as="textarea"
              name="f1"
              type="text"
              rows={3}
              placeholder="Write your response here"
            />

            <Form.Label className={labelForm}>
              How did you hear about us?
            </Form.Label>
            <Form.Control
              style={{ borderRadius: "0" }}
              as="textarea"
              name="f2"
              type="text"
              rows={3}
              placeholder="Write your response here"
            />

            <Form.Label className={labelForm}>
              How can we make this program better?
            </Form.Label>
            <Form.Control
              style={{ borderRadius: "0" }}
              as="textarea"
              name="f3"
              type="text"
              rows={3}
              placeholder="Write your response here"
            />

            <Form.Label className={labelForm}>
              How can this program serve you and your family?
            </Form.Label>
            <Form.Control
              style={{ borderRadius: "0" }}
              as="textarea"
              name="f4"
              type="text"
              rows={3}
              placeholder="Write your response here"
            />

            <Form.Label className={labelForm}>
              Would you like to add any additional services, if so what are
              they?
            </Form.Label>
            <Form.Control
              style={{ borderRadius: "0" }}
              as="textarea"
              name="f5"
              type="text"
              rows={3}
              placeholder="Write your response here"
            />

            {!choiceShow && <SubmitButton />}

            <div
              style={{
                padding: "1rem",
                backgroundColor: "#323232",
                margin: "1rem 0",
              }}
            >
              <Form.Check
                style={{
                  color: "white",
                  textAlign: "center",
                }}
                id="content"
                name="content"
                label="By checking the box you agree to us contacting you."
                value={boxIsChecked}
                onChange={onChangeChoice1}
                required
                onClick={() =>
                  setBoxIsChecked(
                    "I agree to Community of Love contacting me. "
                  )
                }
              />
            </div>
          </Form>
        </Card.Body>

        <FooterScreen />
      </Card>
    </>
  );
};

export default Survey;
