import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./Components/Community of Love/Contact/Contact";
import About from "./Components/Community of Love/About/About";
import Thankyou from "./Components/Thank You/Thankyou";
import Screen from "./Components/Screen";
import CafeScreen from "./Components/CafeScreen";
import ReadMore from "./Components/Community of Love/Testimonials/Readmore/ReadMore";
import PrayerFormScreen from "./Components/Community of Love/Mission Statement/PrayerForm/PrayerFormScreen";
import ServicesPg from "./Components/Community of Love/ServicesPage/ServicesPg";
import Survey from "./Components/Community of Love/ServicesPage/Survey";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Screen />} />
          <Route exact path="/cafe" element={<CafeScreen />} />
          <Route exact path="/prayer" element={<PrayerFormScreen />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/readmore" element={<ReadMore />} />
          <Route exact path="/prayer" element={<PrayerFormScreen />} />
          <Route exact path="/services" element={<ServicesPg />} />
          <Route exact path="/survey" element={<Survey />} />
          <Route exact path="/thankyou/:name" element={<Thankyou />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
