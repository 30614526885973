import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Card } from "react-bootstrap";
import Name from "./Form Fields/Name";
import Email from "./Form Fields/Email";
import Phone from "./Form Fields/Phone";
import Message from "./Form Fields/Message";
import SubmitButton from "./Form Fields/SubmitButton";
import emailjs from "@emailjs/browser";
import PrayerContactStyle from "./PrayerContact.module.css";

const PrayerForm = () => {
  const contact = PrayerContactStyle.contact;
  const form = PrayerContactStyle.form;
  const contactTitle = PrayerContactStyle.contactTitle;

  // for checkbox
  const [boxIsChecked, setBoxIsChecked] = useState(false);
  const [choiceShow, setChoiceShow] = useState(true);

  const onChangeChoice1 = (e) => {
    if (e.target.name === "content" && e.target.checked === true) {
      setChoiceShow(false);
    }

    if (e.target.checked === false) {
      setChoiceShow(true);
    }
  };

  //send email
  const navigate = useNavigate();
  const refForm = useRef();

  const [ffIsValid, setFfIsValid] = useState(false);
  const [details, setDetails] = useState("");
  const [validated, setValidated] = useState(false);

  // check if string is empty
  const checkString = () => {
    if (details.length > 2) {
      setFfIsValid(true);
      setValidated(false);
    }
  };

  // email.js to send data to client
  const sendEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();

    emailjs
      .sendForm(
        "service_xdgp0y4", //service id
        "connect-with-community", //template id
        refForm.current,
        "JY05Vk3JRaOF_oe0Q" //public key
      )
      .then(
        (result) => {
          navigate(`/thankyou/${refForm.current.name.value}`);
          window.location.reload(false);
          console.log("result", result);
        },
        (error) => {
          alert("Failed to send the message, please try again");
          console.log(error.text);
        }
      );
  };

  const validateOnChange = (e) => {
    checkString();

    //fires error messages only - doesn't prevent submit!
    const ckName = e.currentTarget;

    console.log("test", e.currentTarget);
    if (ckName.checkValidity() === false) {
    }
    setValidated(true);
  };

  return (
    <>
      <div className={contact}></div>
      <Card.Title className={contactTitle}>Prayer Request</Card.Title>
      <Form
        id="contact"
        ref={refForm}
        className={form}
        autoComplete="on"
        noValidate
        validated={validated}
        onSubmit={sendEmail}
        onChange={validateOnChange}
      >
        <Name />
        <Email />
        <Phone />
        <Message details={details} setDetails={setDetails} />
        {!choiceShow && (
          <SubmitButton ffIsValid={ffIsValid} validated={validated} />
        )}

        <div
          style={{
            padding: "1rem",
            backgroundColor: "#323232",
            margin: "1rem 0",
          }}
        >
          <Form.Check
            style={{
              color: "white",
              textAlign: "center",
            }}
            id="content"
            name="content"
            label="By checking the box you agree to us contacting you."
            value={boxIsChecked}
            onChange={onChangeChoice1}
            required
            onClick={() =>
              setBoxIsChecked("I agree to Community of Love contacting me. ")
            }
          />
        </div>
      </Form>
    </>
  );
};
export default PrayerForm;
