import React from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import ContactStyle from "../Contact.module.css";

const Email = () => {
  const email = ContactStyle.email;
  const labelForm = ContactStyle.labelForm;

  return (
    <>
    
      <Form.Group as={Col} sm={12}>
      <Form.Label className={labelForm}>Email</Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            id="email"
            className={email}
            name="email"
            type="email"
            required
            minLength={3}
            maxLength={50}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid email format
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </>
  );
};
export default Email;
