const testimonyData = [
  {
    id: 1,
    quote: (
      <>
        <p>
          Hello my name is Sharon Chatman the Founder/CEO of Community of Love.
          It's a pleasure to introduce myself to all of you wonderful people. I
          started this company with the expectations of providing services to
          help all communities all around the world.
        </p>
        <p>
          I am a mother, a grandmother, an aunt, a cousin, a niece,a daughter, a
          godmother, a friend, and most importantly a child of God. I know the
          struggles and challenges many people face everyday and that is why I'm
          so thankful God made a way for this company to be built on a firm
          foundation which is the solid Rock on which I stand and all other
          ground is sinking sand.
        </p>
        <p>
          This is my story and how I was able to overcome it and now we want to
          help others do the same.
        </p>
      </>
    ),
    name: "CEO Sharon Chatman",
  },
  {
    id: 2,
    quote:
      "Sharon Chatman Of Community Of Love Is Someone To Support. I Met Her Earlier 3 Years Ago In The Middle Of The Pandemic While She Was Under God's Gospel Cafe In Hollis, Queens And Her Focus Has Always Been Community Based Within The Southeastern Queens Area. Sharon Is A Woman Of God And Faithfully Commits To Servitude And Loving On Her Family, Friends, And Is Active In Outreach. Sharon Also Plans To For An Expansion Globally To Impact Other Environments And Provide A Myriad Of Opportunities To Those. It's An Honor To Be In Her Life As She Has Inspired Me Also. You Should Do The Same.",
    name: "DIE$EL-E Of Emile's Creations",
  },
  {
    id: 3,
    quote:
      "I have known Miss Sharon Chatman for the past two years. We met when we attended the same Church. From the first time we met, she was warm and friendly. She welcomed my daughter Lilly and myself as a part of her family. She has often taken the time to help me with my daughter. She has babysat, taken her to the park, or even just visited my house to make sure that we are ok. Her heart has always been shown by the way she loves and cares for my daughter and myself. She is a true gift.",
    name: "Sherrol",
  },
  // {
  //   id: 4,
  //   quote:
  //     "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  //   name: "John France",
  // },
  // {
  //   id: 5,
  //   quote:
  //     "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  //   name: "Laura Seenro",
  // },
  // {
  //   id: 6,
  //   quote:
  //     "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  //   name: "Chris Toply",
  // },
];
export default testimonyData;
