import React from "react";
import Content from "./Content";
import ContentDesktop from "./ContentDesktop";
import familyData from "./FamilyData";
import familyDataDesktop from "./FamDataDesktop";
// import { Image, Button } from "react-bootstrap";
import ContentSectionStyles from "./css/Content.module.css";
import { useMediaQuery } from "@mui/material";

const Family = () => {
  const mainContainer = ContentSectionStyles.mainContainer;
  const familyTitleContainer = ContentSectionStyles.familyTitleContainer;
  const subtitleFamContainer = ContentSectionStyles.subtitleFamContainer;
  const hide = ContentSectionStyles.hide;
  const matches1024 = useMediaQuery("(min-width:1024px)");

  const contentList = familyData.map((data) => {
    return <Content key={data.id} {...data} />;
  });

  // for desktop
  const contentListDesktop = familyDataDesktop.map((data) => {
    return <ContentDesktop key={data.id} {...data} />;
  });

  return (
    <>
      <main className={mainContainer}>
       <section className={familyTitleContainer}>
           <h1>
            Family <br className={hide} /> Services List
          </h1> 
        </section>
        {/* <section className={subtitleFamContainer}>
          <h2>Fulfillment Services</h2>
        </section> */}
        {!matches1024 && contentList}
        {matches1024 && contentListDesktop}
      </main>
    </>
  );
};

export default Family;
