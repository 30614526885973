import React from "react";
import { Card, Button } from "react-bootstrap";
import ModalStyle from "./ModalStyle.module.css";
import QuickLinksStyle from "../QuickLinksStyle.module.css";

const ModalGive = ({ closeModal }) => {
  const modalBtn = ModalStyle.modalBtn;
  const modalContent = ModalStyle.modalContent;
  const fillInContentToScroll = ModalStyle.fillInContentToScroll;

  const btn = QuickLinksStyle.btn;

  return (
    <>
      <div className={modalContent}>
        <Card.Header style={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={closeModal} className={modalBtn}>
            X
          </Button>
        </Card.Header>
        <div>
          <Card.Body>
            <div style={{ textAlign: "center" }}>
              <Card.Title>Sponsor Monthly:</Card.Title>
              <Button href="https://square.link/u/ectTKhoa" className={btn}>
                Any Amount
              </Button>

              <Button href="https://square.link/u/L00zqAp2" className={btn}>
                $30 Sponsorship
              </Button>

              <Button href="https://square.link/u/2BIiNxrY" className={btn}>
                $50 Sponsorship
              </Button>

              <Button href="https://square.link/u/32pkaoyE" className={btn}>
                $100 Sponsorship
              </Button>

              <Button href="https://square.link/u/CJB1yaDD" className={btn}>
                $1000 Sponsorship
              </Button>

              <Button href="https://square.link/u/JZiOrcv6" className={btn}>
                $5000 Sponsorship
              </Button>
            </div>
          </Card.Body>
        </div>
      </div>
    </>
  );
};

export default ModalGive;
