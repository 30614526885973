import { useNavigate, useLocation } from "react-router-dom";
import { Form, Card } from "react-bootstrap";
import { useState, useRef } from "react";

import Title from "./Form Fields/Title";
import Name from "./Form Fields/Name";
import Email from "./Form Fields/Email";
import Phone from "./Form Fields/Phone";
import Message from "./Form Fields/Message";
import SubmitButton from "./Form Fields/SubmitButton";
import DividerTestimony from "../Divider/DividerTestimony";
import FooterScreen from "../../Footer/FooterScreen";
import ContactStyle from "./Contact.module.css";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = ContactStyle.form;
  const formStyle = ContactStyle.formStyle;
  const fieldWrapper = ContactStyle.fieldWrapper;

  const navigate = useNavigate();
  const refForm = useRef();

  const [ffIsValid, setFfIsValid] = useState(false);
  const [details, setDetails] = useState("");
  const [validated, setValidated] = useState(false);
  const [phoneFormat, setPhoneFormat] = useState([]);

  // check if string is empty
  const checkString = () => {
    if (details.length > 2) {
      setFfIsValid(true);
      setValidated(false);
    }
  };

  const checkPhoneFormat = () => {
    if (phoneFormat.length === 10) {
      console.log("good");
      setFfIsValid(true);
      setValidated(false);
    }
  };

  const validateOnChange = (e) => {
    checkPhoneFormat();
    checkString();

    //fires error messages only - doesn't prevent submit!
    const ckName = e.currentTarget;

    console.log("test", e.currentTarget);
    if (ckName.checkValidity() === false) {
    }
    setValidated(true);
  };

  // for checkbox
  const [boxIsChecked, setBoxIsChecked] = useState(false);
  const [choiceShow, setChoiceShow] = useState(true);

  // const onChangeChoice1 = (e) => {
  //   if (e.target.name === "content" && e.target.checked === false) {
  //     setChoiceShow(true);
  //   }

  //   if (e.target.checked === true) {
  //     setChoiceShow(false);
  //   }
  // };

  const onChangeChoice1 = (e) => {
    if (e.target.name === "content" && e.target.checked === true) {
      setChoiceShow(false);
    }

    if (e.target.checked === false) {
      setChoiceShow(true);
    }
  };

  let hideDivider;
  const location = useLocation();
  if (location.pathname === "/services") {
    hideDivider = true;
  } else {
    hideDivider = false;
  }

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_xdgp0y4", //service id
        "connect-with-community", //template id
        // console.log("DATA",  refForm.current.name.value),
        refForm.current,
        "JY05Vk3JRaOF_oe0Q" //public key
      )
      .then(
        (result) => {
          //   alert("Message successfully sent!");
          navigate(`/thankyou/${refForm.current.name.value}`);
          // window.location.reload(false);
          console.log("result", result);
        },
        (error) => {
          alert("Failed to send the message, please try again");
          console.log(error.text);
        }
      );
  };

  return (
    <>
      {!hideDivider && (
        <div style={{ marginTop: "1.5rem" }}>
          <DividerTestimony />
        </div>
      )}
      <Card>
        <Card.Body className={formStyle}>
          <Form
            id="contact"
            ref={refForm}
            className={form}
            autoComplete="on"
            noValidate
            validated={validated}
            onSubmit={sendEmail}
            onChange={validateOnChange}
          >
            <Title />
            <div className={fieldWrapper}>
              <Name />
              <Email />
            </div>
            <Phone phoneFormat={phoneFormat} setPhoneFormat={setPhoneFormat} />
            <Message />
            {!choiceShow && <SubmitButton ffIsValid={ffIsValid} />}

            <div
              style={{
                padding: "1rem",
                backgroundColor: "#323232",
                margin: "1rem 0",
              }}
            >
              <Form.Check
                style={{
                  color: "white",
                  textAlign: "center",
                }}
                id="content"
                name="content"
                label="By checking the box you agree to us contacting you."
                value={boxIsChecked}
                onChange={onChangeChoice1}
                required
                onClick={() =>
                  setBoxIsChecked(
                    "I agree to Community of Love contacting me. "
                  )
                }
              />
            </div>
          </Form>
        </Card.Body>
        <FooterScreen />
      </Card>
    </>
  );
};
export default Contact;
