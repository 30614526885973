import React from "react";
import { Image, Button } from "react-bootstrap";
import ServiceStyles from "./css/ServicesPage.module.css";
import Header from "./Header";
import Business from "./Business";
import Family from "./Family";
import Contact from "../Contact/Contact"
import Navigation from "../../ui/Nav";
// import ServicesBusiness from "./services-components/services-content-business";
// import ServicesContact from "./services-components/services-contact";
// import ServicesFamily from "./services-components/services-content-family";

const ServicesPg = () => {
  const mainContainer = ServiceStyles.mainContainer;
  const headerDesktopStyle = ServiceStyles.headerDesktopStyle;
  const subContainer = ServiceStyles.subContainer;

  return (
    <>
    <Navigation/>
      <div className={mainContainer}>
        <div className={subContainer}>
          <Header className={headerDesktopStyle} />
          <Business />
          <Family />
          <Contact/>
          {/*  <ServicesBusiness/>
                <ServicesFamily/>
                <ServicesContact/> */}
        </div>
      </div>
    </>
  );
};

export default ServicesPg;
