import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import TestimonyStyle from "../Testimonials/css/TestimonayStyle.module.css";
import { Button } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";

const GalleryTestimony = () => {
  const pStyle = TestimonyStyle.pStyle;
  const btn = TestimonyStyle.btn;
  const matches375 = useMediaQuery("(min-width:375px)");
  const inline = {
    margin: "1rem 0 0 0",
    fontStyle: "italic",
    fontWeight: "400",
  };

  return (
    <Carousel
      autoPlay={true}
      showThumbs={false}
      showArrows={false}
      infiniteLoop={true}
      showIndicators={false}
      showStatus={false}
      animationHandler="slide"
      transitionTime="500"
      interval={5000}
    >
      <div>
        {!matches375 && (
          <>
            <p className={pStyle}>
              Sharon Chatman Is Someone To Support. Sharon Is A Woman Of God And
              Faithfully Commits To Servitude And Loving On Her Family, Friends,
              And Is Active In Outreach.
            </p>
            <p>
              <p className={pStyle} style={inline}>
                -DIE$EL-E Of Emile's Creations
              </p>
            </p>
          </>
        )}

        {matches375 && (
          <>
            <p className={pStyle}>
              Sharon Chatman Is Someone To Support. I Met Her Earlier 3 Years
              Ago In The Middle Of The Pandemic While She Was Under God's Gospel
              Cafe In Hollis, Queens And Her Focus Has Always Been Community
              Based Within The Southeastern Queens Area. Sharon Is A Woman Of
              God And Faithfully Commits To Servitude And Loving On Her Family,
              Friends, And Is Active In Outreach.
            </p>
            <p className={pStyle} style={inline}>
              -DIE$EL-E Of Emile's Creations
            </p>
          </>
        )}
        <Button href="/readmore" className={btn}>
          READ MORE
        </Button>
      </div>

      <div>
        {!matches375 && (
          <>
            <p className={pStyle}>
              I have known Miss Sharon Chatman for the past two years. We met
              when we attended the same Church. From the first time we met, she
              was warm and friendly. She welcomed my daughter Lilly and myself
              as a part of her family.
            </p>
            <p>
              <p className={pStyle} style={inline}>
                -Sherrol
              </p>
            </p>
          </>
        )}

        {matches375 && (
          <>
            <p className={pStyle}>
              I have known Miss Sharon Chatman for the past two years. We met
              when we attended the same Church. From the first time we met, she
              was warm and friendly. She welcomed my daughter Lilly and myself
              as a part of her family. She has often taken the time to help me
              with my daughter. She has babysat, taken her to the park, or even
              just visited my house to make sure that we are ok. Her heart has
              always been shown by the way she loves.
            </p>
            <p className={pStyle} style={inline}>
              -Sherrol
            </p>
          </>
        )}
        <Button href="/readmore" className={btn}>
          READ MORE
        </Button>
      </div>

      {/*    <div>
        <p className={pStyle}>Ut enim ad minim veniam</p>
        <Button href="/readmore" className={btn}>
          READ MORE
        </Button>
      </div> */}
    </Carousel>
  );
};
export default GalleryTestimony;
