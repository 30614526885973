import React from "react";
import { Image, Button } from "react-bootstrap";
import ContentStyles from "./css/Content.module.css";

// this is the iterable component that will display the properties given to it
const ServicesContent = (props) => {
  const mainContainer = ContentStyles.mainContainer;
  const h3Style = ContentStyles.h3Style;
  const h4Style = ContentStyles.h4Style;
  const articleStyle = ContentStyles.articleStyle;
  const priceRow = ContentStyles.priceRow;
  const imgWrapper = ContentStyles.imgWrapper;
  const imgTitleWrapper = ContentStyles.imgTitleWrapper;

  return (
    <>
      <main className={mainContainer}>
        <div className={imgWrapper}>
          <div className={imgTitleWrapper}>
            <h5>{props.imgTitle}</h5>
            <h4>{props.imgSubTitle}</h4>
            <Image src={props.src} />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3 className={h3Style}>{props.question}</h3>
          <h4 className={h4Style}>{props.subTitle}</h4>
          <article className={articleStyle}>{props.article}</article>
          <div className={priceRow}>
            <p className={props.className}>{props.monthPrice}</p>
            {/* <p>{props.yearPrice}</p> */}
          </div>
        </div>
      </main>
    </>
  );
};

export default ServicesContent;
