import childcareImg from "../../../images/mobile-service/childcare-icon.jpg";
import tutorImg from "../../../images/mobile-service/tutoring-icon.jpg";
import petSitterImg from "../../../images/mobile-service/dog-sitter-icon.jpg";
import carPoolImg from "../../../images/mobile-service/car-pool-icon.jpg";
import houseCleaningImg from "../../../images/mobile-service/house-keeper-icon.jpg";
import coachingImg from "../../../images/mobile-service/coaching-icon.jpg";
import wealthImg from "../../../images/mobile-service/wealth-icon.jpg";
import healthImg from "../../../images/mobile-service/health-icon.jpg";
import contentStyle from "./css/Content.module.css";

const alaCartLabel = contentStyle.alaCartLabel;
const alaCartDiv = contentStyle.alaCartDiv;
const careArticleWidth = contentStyle.careArticleWidth;
const tutorArticleWidth = contentStyle.tutorArticleWidth;
const dogArticleWidth = contentStyle.dogArticleWidth;
const carArticleWidth = contentStyle.carArticleWidth;
const wealthArticleWidth = contentStyle.wealthArticleWidth;

const price = (
  <>
    <div style={{ textAlign: "center" }}>
      <div className={alaCartDiv}>
        <p className={alaCartLabel}>
          <b style={{ fontSize: "4.5vw", fontWeight: "500" }}>
            {/* RECEIVE SERVICE */}
            <br />
            <span style={{ fontWeight: "300" }}>
              *This is a Sponsored Service
              <hr style={{ margin: ".3rem 0" }} />
              Recruitment Services Are Available
            </span>
          </b>
        </p>
      </div>
      <hr style={{ margin: ".8rem 0" }} />
      <div
        style={{ fontSize: "5.5vw", fontWeight: "500", lineHeight: "5.7vw" }}
      ></div>
    </div>
  </>
);

const familyData = [
  {
    id: 1,
    src: childcareImg,
    imgTitle: "SITTER/CHILDCARE",
    imgSubTitle: "SERVICES",
    question: "In need of childcare?",
    article: (
      <div className={careArticleWidth}>
        We know what it's like to need some mommy time, or just mommy and daddy
        time so our company decided to offer a personalized babysitting service
        for families without worry. Our babysitters wear a bodycam for the first
        few times just for you to see the interaction with your precious ones.
        We are equipped with CPR, Infant & and toddler first aid, and general
        first aid certifications. Also, background checks are done on all
        employees every two years, and re-certification. We bring with us
        age-appropriate learning and reading activities for each child and
        foster a friendly and loving environment.
      </div>
    ),
    monthPrice: price,
  },
  {
    id: 2,
    src: tutorImg,
    imgTitle: "TUTORING",
    imgSubTitle: "SERVICES",
    question: (
      <>
        IS YOUR CHILD IN NEED <br /> OF A TUTOR?
      </>
    ),
    article: (
      <div className={tutorArticleWidth}>
        Our company offers state-of-the-art tutoring services via Zoom in any
        subject that your child or children need from qualified and certified
        academic staff and college students. This service is offered to adults
        as well and we ensure the student(s) get the help they need in a patient
        and calm environment.
      </div>
    ),
    monthPrice: price,
  },
  {
    id: 3,
    src: petSitterImg,
    imgTitle: "PET SITTER/DOG WALKING",
    imgSubTitle: "SERVICES",
    question: (
      <>
        WORKING LATE? <br />
        HIRE A DOG WALKER
      </>
    ),
    article: (
      <div className={dogArticleWidth}>
        Do you need to run out or are you going out of town for a few days, and
        don't want to move your pet to a cold and unfamiliar kennel or Vet
        building? Well, we offer Pet services that will allow you to keep your
        pet home and we will ensure all needs are taken care by our loving,
        gentle staff. We also provides no-hassle dog walking services as well.
      </div>
    ),
    monthPrice: price,
  },
  {
    id: 4,
    src: carPoolImg,
    imgTitle: "TRANSPORT & CARPOOL",
    imgSubTitle: "SERVICES",
    question: (
      <>
        DID YOU MISS THAT SCHOOL <br /> BUS AGAIN?
      </>
    ),
    article: (
      <div className={carArticleWidth}>
        What will you do now? How will you get your little one to school on
        time? show up? Is the caretaker unable to take your child(ren) to
        school? Do you need a dependable driver that can help you and your
        family get to where you want to go at a reasonable and minimal cost?
      </div>
    ),
    monthPrice: price,
  },

  {
    id: 5,
    src: houseCleaningImg,
    imgTitle: "HOUSE CLEANING",
    imgSubTitle: "SERVICES",
    question: (
      <>
        DON'T HAVE TIME TO <br />
        CLEAN THE HOUSE?
      </>
    ),
    article:
      "Our housekeepers and de-clutters are seasoned domesticated engineers that have experience working in hospitals, hotels, office building maintenance, and in other homes. We know what it is like to have a dirty home, let our house cleaners do the cleaning while you concentrate on your family and more important ventures",
    monthPrice: price,
  },
  {
    id: 6,
    src: coachingImg,
    imgTitle: "Coaching/Mentoring",
    imgSubTitle: "SERVICES",
    question: "In need of a mentor?",
    article:
      "We provide counseling for individuals, families, groups, etc which is conducted by a licensed counselor, a certified life coach, a certified mentor, and/or a mediator. Don't stay sad or depressed when there is help our staff can provide. Our loving and understanding staff will meet with you over the telephone, on Zoom, or in person.",
    monthPrice: price,
  },
  {
    id: 7,
    src: wealthImg,
    imgTitle: "Building Wealth",
    imgSubTitle: "SERVICES",
    question: "Do you want to build wealth?",
    article: (
      <div className={wealthArticleWidth}>
        How many times have you asked yourself why I do not have the money to
        pay the bills? How can we afford to purchase a house? How are we going
        to pay for college? Or how do I save and invest? Let us walk you through
        some strategic steps to getting you back on track for a brighter future.
      </div>
    ),
    monthPrice: price,
  },
  {
    id: 8,
    src: healthImg,
    imgTitle: "Health and Wellness",
    imgSubTitle: "SERVICES",
    question: (
      <>
        Do you want to improve
        <br /> your health
      </>
    ),
    article:
      "Are you feeling tired? Frustrated and fatigued? Not sure that your diet choices are the best for your body? We are called to be good stewards over our temples (bodies) in order to have a healthy, long, and productive life. We can walk through some life saving tips that can help you get to the road of healing and deliverance.",
    monthPrice: price,
  },
];
export default familyData;
