import React from "react";
import "../style.css";
import { Navbar, Nav, Container } from "react-bootstrap";
import HeaderStyle from "../Community of Love/Header/css/Header.module.css";
import NavStyle from "./Nav.module.css";

const Navigation = () => {
  const navBar = NavStyle.navBar;
  const headerInfoWrapper = HeaderStyle.headerInfoWrapper;
  const iconText = HeaderStyle.iconText;
  const contactIconWrapper = HeaderStyle.contactIconWrapper;

  const headerInfo = (
    <>
      <div className={contactIconWrapper}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={iconText}>PH: 347-840-2428 HRS: 8AM-8PM M-F |</div>
          <div className={iconText}>
            EM:{" "}
            <a href="mailto:communityoflove70@gmail.com">
              communityoflove70@gmail.com
            </a>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Navbar className={navBar} expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/cafe">God's Gospel Cafe</Nav.Link>
              <Nav.Link href="/services">Family Services</Nav.Link>
              <Nav.Link href="/#courses">
                Foreign Language Online Courses
              </Nav.Link>
              <Nav.Link href="/#give">Give/Sponsor</Nav.Link>
              <Nav.Link href="/#give">Request Prayer</Nav.Link>
              <Nav.Link href="/#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
        <div style={{ padding: "1rem 0" }} className={headerInfoWrapper}>
          {headerInfo}
        </div>
      </Navbar>
    </>
  );
};
export default Navigation;
