import { Button } from "react-bootstrap";
import employmentImg from "../../../images/mobile-service/employment-icon.jpg";
import emplyOppImg from "../../../images/mobile-service/employment-opp-icon.jpg";
import contentStyle from "../ServicesPage/css/Content.module.css";

const placeData =
  "Seamlessly post your needs and communicate with qualified talent.";

const businessContact = contentStyle.businessContact;
const alaCartDiv = contentStyle.alaCartDiv;
const btn = contentStyle.btn;

const BusinessPrice = (
  <>
    <p className={businessContact}>
      To Inquire Call or Email: <hr style={{ margin: ".3rem 0" }} />
      347-840-2428 <br />
      <span>
        {" "}
        <a href="mailto:communityoflove70@gmail.com">
          communityoflove70@gmail.com
        </a>
      </span>
    </p>
    <hr style={{ border: "none", margin: ".2rem" }} />

    <aside
      style={{
        padding: ".2rem",
        backgroundColor: "#acb4c6",
        fontWeight: "bold",
        borderRadius: ".3rem",
        fontSize: ".8rem",
        textAlign: "center",
        color: "black",
      }}
    >
      <em>*SMALL BUSINESS RECRUITING SERVICES ARE NOT SPONSORED</em>
    </aside>
  </>
);

const price = (
  <>
    <p className={businessContact}>
      To Inquire Call or Email: <hr style={{ margin: ".3rem 0" }} />
      347-840-2428 <br />
      <span>
        {" "}
        <a href="mailto:communityoflove70@gmail.com">
          communityoflove70@gmail.com
        </a>
      </span>
    </p>
    <hr style={{ border: "none", margin: ".2rem" }} />
  </>
);

const expandedSection = (
  <>
    <p style={{ letterSpacing: "-1px" }}>Are you a volunteer?</p>
    <p
      style={{
        textTransform: "initial",
        fontStyle: "italic",
        fontWeight: "500",
      }}
    >
      Do you have a heart to serve? Are you looking to spread some love and help
      reach the community? Than we want to hear from you!
    </p>
  </>
);

const list = (
  <div
    style={{
      fontWeight: "400",
      fontStyle: "italic",
      textTransform: "initial",
    }}
  >
    We are looking for volunteers in the following areas, inquire below:
    <hr style={{ margin: ".3rem 0" }} />
    ACADEMIC TUTORING
    <hr style={{ margin: ".3rem 0" }} />
    COACHING
    <hr style={{ margin: ".3rem 0" }} />
    FOREIGN LANGUAGE TEACHERS
    <hr style={{ margin: ".3rem 0" }} />
    BUILDING WEALTH
    <hr style={{ margin: ".3rem 0" }} />
    HEALTH AND WELLNESS
  </div>
);

const expandedSection2 = (
  <>
    <hr style={{ border: "none" }} />
    <p style={{ letterSpacing: "-1px" }}>Are you an Intern?</p>
  </>
);

const list2 = (
  <>
    <p style={{ margin: "-1rem 0 -.3rem 0" }}>
      Do you want to gain experience with an integral company? Do you have a
      heart for the community and long to see positive change? Then contact us
      today!
    </p>

    <div
      style={{
        fontWeight: "400",
        fontStyle: "italic",
        textTransform: "initial",
        margin: "2rem 0 0 0 ",
      }}
    >
      We are looking for interns in the following areas, inquire below:
      <hr style={{ margin: ".3rem 0" }} />
      SITTER/CHILDCARE
      <hr style={{ margin: ".3rem 0" }} />
      PET SITTER/DOG WALKER
      <hr style={{ margin: ".3rem 0" }} />
      TRANSPORT & CARPOOLING
      <hr style={{ margin: ".3rem 0" }} />
      HOUSE CLEANING
      <hr style={{ margin: ".3rem 0" }} />
      ACADEMIC TUTORING
      <hr style={{ margin: ".3rem 0" }} />
      COACHING
      <hr style={{ margin: ".3rem 0" }} />
      FOREIGN LANGUAGE TEACHERS
      <hr style={{ margin: ".3rem 0" }} />
      BUILDING WEALTH
      <hr style={{ margin: ".3rem 0" }} />
      HEALTH AND WELLNESS
    </div>
  </>
);

const list3 = (
  <>
    <p>
      We are offering hourly compensation for those employed with us. We offer
      work in the following areas: Please email updated resume to{" "}
      <a href="mailto:communityoflove70@gmail.com">
        communityoflove70@gmail.com
      </a>
      .
    </p>
    <div
      style={{
        fontWeight: "400",
        fontStyle: "italic",
        textTransform: "initial",
        margin: "2rem 0 0 0 ",
      }}
    >
      We are looking for workers in the following areas, inquire below:
      <hr style={{ margin: ".3rem 0" }} />
      TRANSPORT & CARPOOLING
      <hr style={{ margin: ".3rem 0" }} />
      CONTRACTING
      <hr style={{ margin: ".3rem 0" }} />
      BABYSITTING
      <hr style={{ margin: ".3rem 0" }} />
      PET SITTING/WALKING
      <hr style={{ margin: ".3rem 0" }} />
      HOUSE KEEPING
    </div>

    <div className={alaCartDiv}>
      <p>
        <b>Looking to recruit Instead?</b>
        <br />
        {/* Recruitment Services Available */}
        Call 347-840-2428
      </p>
      {/* <Button
        href="https://square.link/u/SSTpt6sc"
        style={{ fontStyle: "normal" }}
        className={btn}
      >
        HELP ME GET HIRED
      </Button> */}
    </div>
  </>
);

const businessData = [
  {
    id: 1,
    src: employmentImg,
    imgTitle: "SMALL BUSINESS RECRUITING SERVICES",
    question: "Are you an Employer looking for Recruitment Services",
    article: placeData,
    monthPrice: BusinessPrice,
  },
  {
    id: 2,
    src: emplyOppImg,
    imgTitle: "FAMILY SERVICES EMPLOYMENT OPPORTUNITIES",
    question: expandedSection,
    subTitle: list,
    // monthPrice: price,
  },

  {
    id: 3,
    question: expandedSection2,
    subTitle: list2,
    monthPrice: price,
  },

  {
    id: 4,
    question: "LOOKING FOR WORK?",
    subTitle: list3,
    // monthPrice: price,
  },
];
export default businessData;
