import React from "react";
import { Image } from "react-bootstrap";
import ContentStyles from "./css/Content.module.css";

// this is the iterable component that will display the properties given to it
const ContentDesktop = (props) => {
  const h3Style = ContentStyles.h3Style;
  const h4Style = ContentStyles.h4Style;
  const articleStyle = ContentStyles.articleStyle;
  const priceRow = ContentStyles.priceRow;
  const imgWrapper = ContentStyles.imgWrapper;
  const imgTitleWrapper = ContentStyles.imgTitleWrapper;
  const quesWrapper = ContentStyles.quesWrapper;

  return (
    <>
      <div className={props.className}>
        <div className={props.cName}>
          <div className={imgTitleWrapper}>
            <h5>{props.imgTitle}<br/>{props.imgSubTitle}</h5>
            <Image src={props.src} />
          </div>
        </div>

        <div className={quesWrapper}>
          <h3 className={h3Style}>{props.question}</h3>
          <h4 className={h4Style}>{props.subTitle}</h4>
          <article className={articleStyle}>{props.article}</article>
          <div className={priceRow}>
            <p>{props.monthPrice}</p>
            <p>{props.yearPrice}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentDesktop;
