import React from "react";
import { Button } from "react-bootstrap";
import ContactStyle from "../Contact.module.css";

const SubmitButton = ({ ffIsValid, validated }) => {
  const btn = ContactStyle.btn;

  return (
    <div style={{ textAlign: "center" }}>
      {ffIsValid ? (
        <Button variant="light" type="submit" className={btn}>
          SUBMIT
        </Button>
      ) : (
        <Button disabled={true} variant="light" type="submit" className={btn}>
          SUBMIT
        </Button>
      )}
    </div>
  );
};
export default SubmitButton;
